import React                   from 'react';
import { graphql }             from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';

import Wrapper   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Separator from '@interness/web-core/src/components/structure/Separator/Separator';
import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading   from '@interness/web-core/src/components/text/Heading/Heading';
import Button    from '@interness/web-core/src/components/elements/Button/Button';
import Link      from '@interness/web-core/src/components/elements/Link/Link';

import Subnav    from '../../components/Subnavigation';
import HeaderImg from '../../components/HeaderImg';

const ListItem = ({ data }) => {
  return (
    <Row>
      <Col md={8}>
        <HeaderImg id={data.headerId}/>
      </Col>
      <Col md={4}>
        <h2 style={{ textAlign: 'center' }}>{data.displayName}</h2>
        <p style={{textAlign: 'justify'}}>{data.excerpt}</p>
        <div style={{textAlign: 'center'}}>
          <Button as={Link} to={`/leistungen/${data.slug}`}>Mehr erfahren</Button>
        </div>
      </Col>
    </Row>
  )
};

const LeistungenPage = (props) => {
  return (
    <>
      <HeaderImg id={'home'}/>
      <Subnav/>
      <Wrapper>
        <Spacer/>
        <Heading>Leistungen</Heading>
        <p>
          Zielsetzung unserer Arbeit ist die perfekte Umsetzung Ihrer Wünsche. Ob Bäder, Küchen, Schlafräume, Wohnräume
          oder individuelle Stauraumlösungen. Mit Kreativität, Liebe zum Detail und bester Qualität, setzen wir Ihre
          Wünsche in Möbel um.</p>
        <Separator/>
        <Container>
          {props.data.pages.nodes.map((page, i) => (
            <React.Fragment key={page.slug}>
              <ListItem data={page}/>
              {i + 1 !== props.data.pages.nodes.length && <Separator/>}
            </React.Fragment>
          ))}
        </Container>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export default LeistungenPage;

export const query = graphql`
  query {
    pages: allPagesYaml {
      nodes {
        displayName
        slug
        headerId
        excerpt
      }
    }
  }
`;